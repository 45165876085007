import {
  Vue, Component, Mixins, Watch 
} from 'vue-property-decorator';

import SidebarInstruction from '../components/SidebarSimulated/SidebarSimulated.vue';
import SidebarQuestions from '../components/SidebarSimulatedQuestions/SidebarSimulatedQuestions.vue';
import SidebarSimulatedResult from '../components/SidebarSimulatedResult/SidebarSimulatedResult.vue';

import { SIMULATED_COVERS, SIMULATED_IDS } from '@/constant/SimulatedCovers';

import SetSidebar from '@/mixins/SetSidebar';

const ROUTE_INSTRUCTION = 'SimulatedInstruction';
const ROUTE_QUESTIONS = 'SimulatedQuestions';
const INDEX_FIRST_DAY_SIMULATED = 0;

@Component
export default class ConfigSidebar extends Mixins(SetSidebar) {
  mounted() {
    this.$store.commit('setPermanenceSimulatedData', false);
    this.setSidebarSimulated(this.nameCoverSimulated);
  }

  beforeDestroy() {
    this.$store.commit('savePreviousPageRoute', '');
    if (!this.$store.getters.permanenceSimulatedData) this.$store.commit('saveSimulatedTitleCover', {});
  }

  get isFirstDaySimulated() {
    const { indexSimulatedDay } = this.$store.getters;
    return indexSimulatedDay === INDEX_FIRST_DAY_SIMULATED;
  }

  get componentSidebar() {
    const { name } = this.$route;

    if (name === ROUTE_INSTRUCTION) return SidebarInstruction;
    if (name === ROUTE_QUESTIONS) return SidebarQuestions;

    return SidebarSimulatedResult;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get nameCoverSimulated() {
    const url = this.$store.getters.infoSimulatedLog.topId.URL;

    let nameUrl = '';
    let indexNameUrl = null;

    if (url.indexOf(SIMULATED_COVERS[SIMULATED_IDS.anteriores]) !== -1) {
      nameUrl = SIMULATED_COVERS[SIMULATED_IDS.anteriores];
      indexNameUrl = url.indexOf(SIMULATED_COVERS[SIMULATED_IDS.anteriores]);
    } else if (url.indexOf(SIMULATED_COVERS[SIMULATED_IDS.revisao]) !== -1) {
      nameUrl = SIMULATED_COVERS[SIMULATED_IDS.revisao];
      indexNameUrl = url.indexOf(SIMULATED_COVERS[SIMULATED_IDS.revisao]);
    } else {
      nameUrl = SIMULATED_COVERS[SIMULATED_IDS.cursos];
      indexNameUrl = url.indexOf(SIMULATED_COVERS[SIMULATED_IDS.cursos]);
    }

    const nameSimulated = url.substring(indexNameUrl, indexNameUrl + nameUrl.length);
    return nameSimulated;
  }

  get titleSimulated() {
    return this.$store.getters.simulatedTitleCover.title;
  }

  setSidebarSimulated(thumb: string, color?: string) {
    const { id } = this.$route.params;
    const ComponentClass = Vue.extend(this.componentSidebar);
    const instance = new ComponentClass({
      propsData: {
        thumb,
        colorBook: color,
        isSecondDaySimulated: !this.isFirstDaySimulated,
        idSimulated: id,
        titleSimulated: this.titleSimulated
      }
    });

    this.showSidebar(instance);
  }

  @Watch('isMobile')
  setShowSidebar() {
    if (this.$responsive.isMobile) this.$store.commit('setSidebar', false);
    else this.$store.commit('setSidebar', true);
  }

  showSidebar(instance: Record<string, any>) {
    this.$store.commit('setSidebarExistence', true);
    this.setSidebar(instance);
  }
}
