













































































import { Vue, Component } from 'vue-property-decorator';

import {
  CONST_RESULT, NO_GRADE, PARTIAL_AVERAGE_TEXT, GENERAL_AVERAGE_TEXT 
} from './constants';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    BoxContainer,
    FeedbackUser
  }
})
export default class MyResult extends Vue {
  private dataResult = CONST_RESULT;

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get resultSimulated() {
    return this.$store.getters.infoSimulatedResult;
  }

  get rightAndWrongQuestions() {
    return this.$store.getters.rightAndWrongQuestions;
  }

  get scoreSimulated() {
    return this.resultSimulated.simulations[0] || this.resultSimulated.simulations[1];
  }

  get scoreAverage() {
    return this.scoreSimulated?.scoreAverage;
  }

  get redactionCompleted() {
    return this.resultSimulated ? this.resultSimulated?.days_completed?.redaction_completed : false;
  }

  get textScoreAverage() {
    if (this.redactionCompleted && !this.scoreAverage) return PARTIAL_AVERAGE_TEXT;

    return GENERAL_AVERAGE_TEXT;
  }

  get resultScoreAverage() {
    if (this.redactionCompleted && !this.scoreAverage) return this.partialScoreAverage;

    return Number(this.scoreAverage).toFixed(2);
  }

  get partialScoreAverage() {
    if (!this.scoreSimulated) return NO_GRADE;

    const {
      scoreHuman, scoreLanguage, scoreMath, scoreNature 
    } = this.scoreSimulated;

    const scores = [scoreHuman, scoreLanguage, scoreMath, scoreNature].map((value) => parseFloat(value));

    const totalScores = scores.reduce((acc, score) => acc + score, 0);
    const averageScore = totalScores / scores.length;

    return averageScore.toFixed(2);
  }

  get showGeneralAverageWarningtext() {
    return this.resultSimulated && this.redactionCompleted && !this.scoreAverage;
  }

  showNote(idArea: string) {
    return Number(this.scoreSimulated[idArea]).toFixed(2) || NO_GRADE;
  }

  showQuantityQuestionsCorrect(idArea: string) {
    let correctQuestion = 0;
    Object.keys(this.rightAndWrongQuestions).forEach((key: string) => {
      if (idArea === key) correctQuestion = this.rightAndWrongQuestions[key].totalCorrect;
    });
    return correctQuestion;
  }

  showQuantityQuestionsWrong(idArea: string) {
    let wrongQuestion = 0;
    Object.keys(this.rightAndWrongQuestions).forEach((key: string) => {
      if (idArea === key) wrongQuestion = this.rightAndWrongQuestions[key].totalWrong;
    });
    return wrongQuestion;
  }

  goToSimulationSISU() {
    const { id } = this.$route.params;

    this.setTrackAmplitude();

    this.$router.push({
      name: 'EnemSimulator',
      params: {
        id
      }
    });
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'MyResult',
          local: 'Simular Resultado SISU'
        }
      }
    });
  }
}
