



















import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store';
import router from '@/router';

import RadioBox from '@/components/Input/RadioBox/RadioBox.vue';
import ContainerSidebarSimulated from '../ContainerSidebarSimulated/ContainerSidebarSimulated.vue';

@Component({
  components: {
    ContainerSidebarSimulated,
    RadioBox
  }
})
export default class SidebarSimulatedQuestions extends Vue {
  @Prop() thumb!: string;
  @Prop() titleSimulated!: string;
  @Prop() colorBook!: string;
  @Prop() isSecondDaySimulated!: boolean;

  private radiolist = {
    name: '',
    array: [
      {
        title: 'Uma por vez',
        value: 'one',
        checked: true
      },
      {
        title: 'Quero que todas apareçam',
        value: 'all',
        checked: false
      }
    ]
  };

  get routeInstructionSimulated() {
    return `/simulado/${router.currentRoute.params.id}`;
  }

  getOptionPicked(optionPicked: string) {
    this.radiolist.name = optionPicked;
    store.commit('saveFormatShowingQuestions', optionPicked);
  }
}
