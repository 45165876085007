const TAB_NAME_LANGUAGES = 'lc_lt';

const DISCIPLINE_ENGLISH = 'Inglês';
const DISCIPLINE_SPANISH = 'Espanhol';

const ID_LANGUAGE_SPANISH = 13;
const ID_LANGUAGE_ENGLISH = 3;

const OPTIONS_CORRECT = 1;
const OPTIONS_INCORRECT = 0;

const NEXT_PREVIOUS_TAB_NUMBER = 1;

const LIST_TAB = [
  { title: 'LC', id: 'lc_lt', active: true },
  { title: 'CH', id: 'ch_ct', active: false },
  { title: 'CN', id: 'cn_ct', active: false },
  { title: 'MT', id: 'mt', active: false }
];

const TAB_ACTIVE_LANGUAGE = 'lc_lt';

export const SIMULATED_CONSTANT = {
  TAB_NAME_LANGUAGES,
  DISCIPLINE_ENGLISH,
  DISCIPLINE_SPANISH,
  ID_LANGUAGE_SPANISH,
  ID_LANGUAGE_ENGLISH,
  OPTIONS_CORRECT,
  OPTIONS_INCORRECT,
  NEXT_PREVIOUS_TAB_NUMBER,
  LIST_TAB,
  TAB_ACTIVE_LANGUAGE
};
