










import { Component, Vue, Prop } from 'vue-property-decorator';

import ContainerSidebarSimulated from '../ContainerSidebarSimulated/ContainerSidebarSimulated.vue';

@Component({
  components: {
    ContainerSidebarSimulated
  }
})
export default class SimulatedSidebarResult extends Vue {
  @Prop() thumb!: string;
  @Prop() titleSimulated!: string;
  @Prop() colorBook!: string;
}
