














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ExerciseContainer extends Vue {
  @Prop({ default: false }) all!: boolean;
}
