





































import { Component, Vue, Prop } from 'vue-property-decorator';

import store from '@/store';

import Checkbox from '@/components/Input/Checkbox/index.vue';

const COLOR_CHECKBOX = 'var(--neutralcolor-low-light)';
const INDEX_FIRST_DAY_SIMULATED = 0;

@Component({
  components: {
    Checkbox
  }
})
export default class SimulatedDayButtons extends Vue {
  @Prop({ default: false }) completedFirstDay!: boolean;
  @Prop({ default: false }) isFinishSimulated!: boolean;

  private COLOR_CHECKBOX = COLOR_CHECKBOX;

  get isFirstDaySimulated() {
    const { indexSimulatedDay } = store.getters;
    return indexSimulatedDay === INDEX_FIRST_DAY_SIMULATED;
  }

  get isSentRedaction() {
    if (this.isFirstDaySimulated) return false;
    const statusRedaction = store.getters.infoSimulatedLog?.redaction_completed;
    const statusRedactionInfo = store.getters.infoSimulatedQuestion?.days_completed?.redaction_completed;
    const statusRedactionResult = store.getters.infoSimulatedResult?.days_completed?.redaction_completed;

    if (statusRedaction) return statusRedaction;
    if (statusRedactionInfo) return statusRedactionInfo;
    if (statusRedactionResult) return statusRedactionResult;
    return false;
  }
}
