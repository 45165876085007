

































import { Component, Vue, Prop } from 'vue-property-decorator';

import store from '@/store';

import ContainerSidebar from '@/components/ContainerSidebar/ContainerSidebar.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Book from '@/components/Book/index.vue';
import BookSidebarLoading from '@/components/BookSidebarLoading/index.vue';

import SimulatedDayButtons from '../SimulatedDayButtons/SimulatedDayButtons.vue';

const ROUTE_SIMULATED_QUESTION = 'SimulatedQuestions';

@Component({
  components: {
    ContainerSidebar,
    ContainerFluid,
    Book,
    SimulatedDayButtons,
    BookSidebarLoading
  }
})
export default class ContainerSidebarSimulated extends Vue {
  @Prop() thumb!: string;
  @Prop() colorBook!: string;
  @Prop() titleSimulated!: string;
  @Prop({ default: false }) isSecondDayOrResultSimulated!: boolean;
  @Prop({ default: false }) isFinishSimulated!: boolean;
  @Prop({ default: false }) dateIsPast!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ required: false }) currentRoute!: string;
  @Prop({ required: false }) url!: string;

  get showLoading() {
    return this.isLoading || !this.thumb.length;
  }

  get isShowComponentSimulatedDayButtons() {
    return this.currentRoute || this.isSecondDayOrResultSimulated || this.dateIsPast;
  }

  buttonBackClicked() {
    if (this.currentRoute === ROUTE_SIMULATED_QUESTION) store.commit('setPermanenceSimulatedData', true);
  }
}
