














import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import ProgressLinearAnimated from '@/components/ProgressLinearAnimated/index.vue';

@Component({
  components: {
    Modal,
    ProgressLinearAnimated
  }
})
export default class ModalSimulationResultLoading extends Vue {
  get progressPercentage() {
    return this.$store.getters.simulatedResultPercentageProgress;
  }
}
