














import { Component, Vue, Prop } from 'vue-property-decorator';

import Arrow from '@/assets/icons/Arrow.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    Arrow
  }
})
export default class NavigationQuestion extends Vue {
  @Prop({ default: false }) isFirstQuestion!: boolean;
  @Prop({ default: false }) isLastQuestion!: boolean;

  created() {
    this.onKeyDown();
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.checkKey);
  }

  previousQuestion() {
    if (this.isFirstQuestion) return;
    this.setTrackAmplitude('Botão - Anterior');

    this.$emit('previous-questions');
  }

  nextQuestion() {
    if (this.isLastQuestion) return;
    this.setTrackAmplitude('Botão - Próxima');

    this.$emit('next-questions');
  }

  onKeyDown() {
    document.addEventListener('keydown', this.checkKey);
  }

  checkKey(event: any) {
    const { key } = event;

    switch (key) {
      case 'ArrowLeft':
        this.previousQuestion();
        break;
      case 'ArrowRight':
        this.nextQuestion();
        break;
      default:
        break;
    }
  }

  setTrackAmplitude(local: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'NavigationQuestion',
          local
        }
      }
    });
  }
}
