


















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';

import ContainerSidebarSimulated from '../ContainerSidebarSimulated/ContainerSidebarSimulated.vue';

import SimulationService from '@/services/Simulation/SimulationService';

import { SimulatedDay } from '../../interface/ISimulated';

import store from '@/store';

const PERMISSION_SAVE_GET_DATA_STORE = true;
const INDEX_SIMULATED_FIRST_DAY = 0;
const INDEX_SIMULATED_SECOND_DAY = 1;
const SIXTY_MINUTES = 60;
const MSG_ERRO_SIMULATED = 'Erro ao carregar os dados do simulado. Tente novamente em instantes.';

@Component({
  components: {
    ContainerSidebarSimulated
  }
})
export default class SidebarSimulated extends Vue {
  @Prop() thumb!: string;
  @Prop() titleSimulated!: string;
  @Prop() colorBook!: string;
  @Prop() idSimulated!: string;
  @Prop() isSecondDaySimulated!: boolean;

  private simulatedDuration: number | null = null;
  private dateResultSimulated: string | null = null;
  private infoSimulated: Array<SimulatedDay> = [];
  private hours = 0;
  private minutes = 0;
  private formatTime = '';
  private isLoading = true;

  private ServiceSimulation = new SimulationService();

  async created() {
    await this.getSimulatedQuestions();
    this.calculateSimulationTime();
    this.setResultDateSimulated();
  }

  get indexSimulatedDay() {
    return store.getters.indexSimulatedDay;
  }

  get dateFirstDay() {
    return this.infoSimulated[INDEX_SIMULATED_FIRST_DAY]?.init_date;
  }

  get dateSecondDay() {
    return this.infoSimulated[INDEX_SIMULATED_SECOND_DAY]?.init_date;
  }

  get simulatedDay() {
    return {
      firstDay: format(parseISO(this.dateFirstDay), 'dd/MM'),
      secondDay: format(parseISO(this.dateSecondDay), 'dd/MM')
    };
  }

  async getSimulatedQuestions() {
    try {
      this.isLoading = true;
      store.commit('setIsLoadingQuestionsSimulated', true);
      const response = await this.ServiceSimulation.getSimulated(
        Number(this.idSimulated),
        PERMISSION_SAVE_GET_DATA_STORE
      );
      if (response) this.infoSimulated = response.results;
    } catch (error) {
      store.dispatch('Toast/setToast', {
        text: MSG_ERRO_SIMULATED,
        status: 'error'
      });
    } finally {
      this.isLoading = false;
      store.commit('setIsLoadingQuestionsSimulated', false);
    }
  }

  get dateIsPast() {
    const dateCurrent = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    if (this.dateFirstDay && this.dateSecondDay) return this.dateFirstDay < dateCurrent && this.dateSecondDay < dateCurrent;
    return true;
  }

  calculateSimulationTime() {
    const calculationHours = this.infoSimulated[this.indexSimulatedDay]?.duration / SIXTY_MINUTES;
    this.hours = Math.floor(calculationHours);
    const calculationMinutes = (calculationHours - this.hours) * SIXTY_MINUTES;
    this.minutes = Math.round(calculationMinutes);

    this.formatTime = `
      ${this.hours ? `${this.hours}${this.minutes ? 'h' : ' horas'}` : ''}
      ${
  this.minutes
    ? `${this.minutes} ${this.hours ? 'min' : `${this.minutes > 1 ? 'minutos' : 'minuto'}`}`
    : ''
}`;
  }

  setResultDateSimulated() {
    const dateResult = this.infoSimulated.find(
      (simulatedDay: Record<string, any>) => simulatedDay.post_result_release
    )?.post_result_release;
    const dateCurrent = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    if (!dateResult) {
      this.dateResultSimulated = null;
      return;
    }
    this.dateResultSimulated = dateResult < dateCurrent ? null : format(parseISO(dateResult), 'dd/MM');
  }
}
