export const CONST_RESULT = {
  scores: [
    {
      group: [
        {
          id: 'scoreLanguage',
          title: 'Linguagens e Códigos (LC)',
          subtitle: 'Nota com base no TRI'
        },
        {
          id: 'lc_lt',
          title: 'Total de acertos',
          subtitle: ''
        },
        {
          id: 'lc_lt',
          title: 'Total de erros',
          subtitle: ''
        }
      ]
    },
    {
      group: [
        {
          id: 'scoreHuman',
          title: 'Ciências Humanas (CH)',
          subtitle: 'Nota com base no TRI'
        },
        {
          id: 'ch_ct',
          title: 'Total de acertos',
          subtitle: ''
        },
        {
          id: 'ch_ct',
          title: 'Total de erros',
          subtitle: ''
        }
      ]
    },
    {
      group: [
        {
          id: 'scoreNature',
          title: 'Ciências da Natureza (CN)',
          subtitle: 'Nota com base no TRI'
        },
        {
          id: 'cn_ct',
          title: 'Total de acertos',
          subtitle: ''
        },
        {
          id: 'cn_ct',
          title: 'Total de erros',
          subtitle: ''
        }
      ]
    },
    {
      group: [
        {
          id: 'scoreMath',
          title: 'Matemática (MT)',
          subtitle: 'Nota com base no TRI'
        },
        {
          id: 'mt',
          title: 'Total de acertos',
          subtitle: ''
        },
        {
          id: 'mt',
          title: 'Total de erros',
          subtitle: ''
        }
      ]
    }
  ]
};

export const NO_GRADE = 'Sem nota';
export const PARTIAL_AVERAGE_TEXT = 'Sua média parcial: ';
export const GENERAL_AVERAGE_TEXT = 'Sua média geral: ';
